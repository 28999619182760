@import "../utils/sassVariables.scss";
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,300;1,300&?family=Oswald&display=swap');

#subPageHeaderContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    border-bottom: 3px solid $color-primary-teal;
    box-shadow: 0px 2px 5px $color-secondary-black;
    height: 50px; 
    background-color: $color-primary-black;   
}

#subpageHeaderPosition {
    position: sticky; 
    top: 0;
}

#subPageHeaderLogo {
    max-width: 40px;
    max-height: 45px;
    margin-left: 10%;
}

#subPageMainContainer { 
    min-height: 100vh;
    background-color: $color-secondary-black;
}

#servicesMainContainer {
    display: flex; 
    justify-content: center;
    align-items: center;     
}

#servicesMainBox {
    background-color: $color-primary-gray;
    width: 90%;
    border: 2px solid $color-primary-black;
    border-radius: 5%;
    box-shadow: 0px 2px 5px $color-primary-teal;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
    margin-top: 10vh;   
    margin-bottom: 10vh;
}

#servicesMainTitle {
    border-bottom: 3px solid $color-secondary-teal;
    width: 70%;
    font-family: 'Oswald', sans-serif;
}

#servicesMainCardRow {
    display: flex; 
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.servicesMainCard {
    background-color: $color-primary-black;
    margin: 10px;
    color: $color-primary-gray;
    border: 2px solid $color-primary-teal;
    border-radius: 5%;
    box-shadow: 0px 2px 5px $color-primary-black;
    padding: 2%;
    display: flex;
    flex-direction: column;
    width: 225px; 
    height: 175px;    
}

.servicesCardTitle {
    font-family: 'IBM Plex Serif', serif;
}

.servicesCardIcon {
    margin: 5%;
}

.servicesCardButtonContainer {
    margin: 5%;
}

.servicesCardButton {
    background-color: $color-primary-gray;
    color: $color-primary-black;
    border: 1px solid $color-secondary-black;
    box-shadow: 0px 2px 5px $color-secondary-teal;
    font-family: Arial, Helvetica, sans-serif;
    padding: 5px;    
    &:hover {        
        box-shadow: -2px 4px 5px $color-primary-teal;
        font-weight: bold;
    }
}

#backToServicesContainer {
    display: flex; 
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

#backToServicesBox {
    background-color: $color-primary-black;
    border: 2px solid $color-primary-gray;
    border-radius: 5%;
    width: 60px;
    height: 45px;
    display: flex; 
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 5px $color-secondary-teal;
}

.backToServicesIcon {
    color: $color-secondary-teal;
    font-size: 30px;
    &:hover { 
        font-weight: bold;
        color: $color-primary-gray;
    }
}

.servicesEngineeringContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    padding-bottom: 20%; 
    flex-direction: column;   
}

.servicesEngineeringBox {
    width: 90%; 
    background-color: $color-primary-gray;
    border: 3px solid $color-secondary-teal;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    font-family: 'IBM Plex Serif', serif;

    p, ul {
        font-size: 1.7vh;
        margin-left: 2px;
        margin-right: 2px;
    }
}

.servicesEngineeringTitleBox {
    width: 90%; 
    border-bottom: 3px solid $color-secondary-teal;
    align-self: center;
}

.servicesEngineeringTitleText {
    font-family: 'Oswald', sans-serif;
}

#contactUsLine {
    width: 90%; 
    border-top: 3px solid $color-primary-black;
    align-self: center;   
}

#servicesEngineeringContactButtonContainer {
    margin-bottom: 1%;
    display: flex; 
    flex-direction: column;
}

#servicesEngineeringContactButton {
    background-color: $color-primary-black;
    color: $color-primary-teal;
    border: 1px solid $color-secondary-teal;
    box-shadow: 0px 2px 5px $color-secondary-black;
    padding: 5px;
    margin: 10px;
    &:hover {
        color:$color-primary-gray;
        box-shadow: -2px 4px 5px $color-secondary-black;
    }
}

#experienceMainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

#experienceMainIconContainer {
    display: flex; 
    justify-content: space-around;
    align-items: center; 
    flex-wrap: wrap;   
}

.experienceMainIcon {
    margin: 2%;
}

#contactMainContactForm {
    display: flex; 
    justify-content: center;
    align-items: center;   
    flex-direction: column;         
    height: 100%;        
    
    label {
        margin: 1%;
    }
    
    textarea, input {        
        width: 100%;
        border:0; 
        border-bottom:1px solid $color-primary-black;         
        padding: 2%;     
        background-color: $color-secondary-teal;   
        box-shadow:0 0 2px 1px $color-secondary-black;
        border-radius:10px;
    }

    button {
        background-color: $color-primary-black;
        color: $color-primary-teal;
        padding: 7px;
        margin: 5px;
        border:2px solid $color-secondary-teal; 
        border-radius: 5px;
        &:hover {
            color:$color-primary-gray;
            box-shadow: -2px 4px 5px $color-secondary-black;
        }
    }
}

#contactMainSocialBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    a {
        margin: 1%;
    }
}

.contactMainSocialIconBox {
    margin: 1%;
}

.contactMainSocialIcon {
    color: $color-secondary-teal;
    font-size: 45px;
    &:hover { 
        color: $color-primary-black;
    }
}

#qualityProcessDiagram {
    border:  3px solid $color-primary-teal;
    border-radius: 5%; 
    max-width: 90%;
}

#subpageFooterPosition {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

#subPageMainHomeButtonContainer {    
    margin-top: 2vh; 
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%; 
    background-color: $color-primary-black;
    border-top: 3px solid $color-secondary-teal;
}

#subPageMainHomeButton {
    background-color: $color-primary-teal;
    border: 1px solid $color-primary-black;
    border-radius: 10%;
    color: $color-secondary-black;
    box-shadow: 0px 2px 5px $color-secondary-black;
    padding: 10px;
    margin: 5px;
    &:hover {
        color:$color-secondary-teal;
        box-shadow: -2px 4px 5px $color-secondary-teal;
    }
}
